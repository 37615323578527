import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Lottie from 'react-lottie';
import ContactVector from '../jsonicon/contact_us.json';
import axios from 'axios';


function ContactForm() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: ContactVector,
    };

    const initialValues = {
        email: '',
        name: '',
        number: '',
        message: '',
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .max(50, 'Must be 50 characters or less')
            .required('Required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
        number: Yup.string()
            .required('Required'),
    });

    const handleSubmit = async (values, { resetForm }) => {
        console.log('Form submitted with values:', values);
        try {
            const response = await axios.post('https://tronix.onrender.com/contact', values);
            // Handle the response, e.g., display a success message
            console.log('Response from the API:', response);
            // Clear the form or perform any other necessary actions
            resetForm({ values: '' });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='bg-slate-50'>
            <div className='max-w-7xl mx-auto p-6 lg:px-8 '>
                <div className='lg:grid lg:grid-cols-2 gap-6 block'>
                    <div>
                        <Lottie
                            options={defaultOptions}

                            className="relative right-8 lg:h-550 lg:w-550"
                        />
                    </div>
                    <div className='lg:p-10  pt-10 '>
                        <h3 className='text-3xl font-bold text-left '>
                            Do You Have A Project In Mind?

                            {/* Get In Touch<span className='text-blue-700'>!</span> */}
                        </h3>
                        <p className='text-lg text-gray-400 pb-12 pt-3'>Guaranteed Response within one Business Day!

                        </p>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            <Form>
                                <div className="form-group mb-7">
                                    <Field
                                        type="text"
                                        id="name"
                                        name="name"
                                        className='w-full h-12 px-2   border-2 rounded-xl'
                                        placeholder='Your name'
                                    />
                                    <ErrorMessage name="name" component="div" className="error text-red-500" />
                                </div>
                                <div className="form-group mb-7">
                                    <Field
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder='Your email'
                                        className='w-full h-12 px-2  border-2 rounded-xl'
                                    />
                                    <ErrorMessage name="email" component="div" className="error text-red-500" />
                                </div>
                                <div className="form-group mb-7">
                                    <Field
                                        type="number"
                                        id="number"
                                        name="number"
                                        className='w-full h-12 px-2  border-2 rounded-xl'
                                        placeholder='Contact Number'
                                    />
                                    <ErrorMessage name="number" component="div" className="error text-red-500" />
                                </div>
                                <div className="form-group mb-7">
                                    <div></div>
                                    <Field
                                        as="textarea"
                                        id="message"
                                        name="message"
                                        className='w-full h-32 px-2  border-2 rounded-xl'
                                        placeholder='Your message (optional)'
                                    />
                                </div>
                                <button className='h-12 bg-blue-700 w-full  rounded-lg text-white font-bold text-base' type="submit">Submit</button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
