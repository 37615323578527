import React from 'react'
import Lottie from 'react-lottie';
import aboutChoose from '../jsonicon/choose.json';

export default function About() {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: aboutChoose,
    };
    return (
        <div className=' bg-slate-100 py-10'>
            <div className='max-w-7xl mx-auto p-6 lg:px-8'>
                <div className='lg:grid lg:grid-cols-2 gap-6 block'>
                    <div className='lg:mb-0 mb-8' >
                        <Lottie options={defaultOptions}

                            className="relative lg:h-[500px] w-[550px] right-20 lg:block"
                        />
                    </div>
                    <div>
                        <div>
                            <div className='border-left'>
                                <h3 className='font-bold text-2xl pl-3'>Why Choose TronixTechIT</h3>
                                <p className='text-gray-500 mb-8 pt-2 pl-3'>Make your business stand Out in the market than your competitors</p>
                            </div>

                            <ul className='list-choose'>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                    </span>

                                    <label>2 years of experience in IT consulting and software development.</label>
                                </li>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                    </span>
                                    <label>
                                        Expertise in a variety of industries, including manufacturing, healthcare, automotive, and retail.
                                    </label>
                                </li>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                    </span>
                                    <label>
                                        100+ accredited and prestigious resources.
                                    </label>
                                </li>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                    </span>

                                    <label>
                                        Fintech, IoT, backend development, Salesforce consulting, retail, and healthcare solutions have all received awards and recognition.
                                    </label>
                                </li>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                    </span>
                                    <label>
                                        successfully completed more than a thousand Web and mobile app development projects.
                                    </label>
                                </li>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                    </span>
                                    <label>
                                        Having a 70% overall client retention rate.
                                    </label>
                                </li>
                                <li>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                                        </svg>
                                    </span>
                                    <label>
                                        2 years of software development and consulting have produced multidisciplinary expertise.
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
