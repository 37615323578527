import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../jsonicon/BannerHome.json';

export default function Banner() {
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
    };
    return (
        <div>
            <div className='max-w-7xl mx-auto p-6 lg:px-8'>
                <div className='lg:grid lg:grid-cols-2 lg:gap-4 block'>
                    <div className='py-24'>
                        <h1 className='text-6xl font-bold mb-6 leading-[4rem]'>Hire On Demand
                            Developers</h1>
                        <p className='py-8 text-gray-600 font-semibold text-lg'>No matter what requirement you’ve got in mind, our team will help you with relevant candidates.</p>
                        <a href='#contact' className='block'>
                            <button className='h-16 bg-blue-700 px-16 rounded-lg text-white font-bold text-base'>Get Developers</button></a>
                    </div>
                    <div className='text-xl bg-image-banner lg:block hidden'>
                        <Lottie options={defaultOptions}
                            height={500}
                            width={750}
                            className="hidden lg:block"
                        />
                    </div>
                </div>
            </div>
        </div >
    )
}
