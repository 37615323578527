import React from 'react'
export default function Footer() {
    return (
        <div className='bg-slate-900'>
            <div className='max-w-7xl mx-auto p-6 lg:px-8'>
                <div className='lg:grid lg:grid-cols-4 gap-6 block'>
                    <div className='lg:mb-0 mb-6'>
                        <img src='/TTlogo.png' alt='logo' className='w-60 invert' />
                    </div>
                    <div className='lg:mb-0 mb-6'>
                        <h2 className='text-white text-xl font-bold pt-2 pb-4'>About</h2>
                        <p className='text-white'>
                            TronixTechIT was established in 2022 to advance innovation in the IT industry through excellence and openness. TronixTechIT has developed over the past year into one of the top consultancies in the world, providing offshore development services across the globe (USA, UK, Australia, Europe, Canada, India, Singapore, Africa).
                        </p>
                    </div>
                    <div className='lg:mb-0 mb-6'>
                        <h2 className='text-white text-xl font-bold pt-2 pb-4'>Useful Links
                        </h2>
                        <ul className='underlist'>
                            <li>Home</li>
                            <li>About</li>
                            <li>Service</li>
                            <li>Our Process</li>
                            <li>Contact us</li>
                        </ul>
                    </div>
                    <div>
                        <h2 className='text-white text-xl font-bold pt-2 pb-4'>Contact Us
                        </h2>

                        <ul className='underlist'>
                            <li className='flex'>

                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>
                                <label className='ml-2'>8964070318</label>
                            </li>
                            <li className='flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M6.912 3a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H6.912zm13.823 9.75l-2.213-7.191A1.5 1.5 0 0017.088 4.5H6.912a1.5 1.5 0 00-1.434 1.059L3.265 12.75H6.11a3 3 0 012.684 1.658l.256.513a1.5 1.5 0 001.342.829h3.218a1.5 1.5 0 001.342-.83l.256-.512a3 3 0 012.684-1.658h2.844z" clipRule="evenodd" />
                                </svg>
                                <label className='ml-2'>business@tronixtechit.in</label>

                            </li>
                            <li className='flex'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-16 h-6">
                                    <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                                </svg>


                                <label className='ml-2'>302 Sakina Appt, 9-10 Bohra Bakhal Near Marothiya Bazaar, Indore, Madhya Pradesh 452001, IN</label>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='p-4 bg-slate-900'>
                <h3 className='text-base font-bold text-white text-center'>Developed and Maintained by TronixTechIT.</h3>
            </div>
        </div>
    )
}