import './App.css';
import Footer from './commoncomponent/footer';
import Header from './commoncomponent/header';
import Banner from './homecomponent/Banner';
import Service from './homecomponent/Service';
import About from './homecomponent/about';
import Contact from './homecomponent/contact';
import Process from './homecomponent/process';

function App() {
  return (
    <div className="App">
      <Header />
      <div id="banner">
        <Banner />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="services">
        <Service />
      </div>
      <div id='process'>
        <Process />
      </div>
      <div id='contact'>
        <Contact />
      </div>

      <Footer />
    </div>

  );
}

export default App;
