import React from 'react'
import Lottie from 'react-lottie';
import animationData from '../jsonicon/animation_lm8skkv1.json'
import animationData1 from '../jsonicon/animation_lm8sor8j.json'
import animationData2 from '../jsonicon/animation_lm8sm2ak.json'

export default function Process() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData1,
    };
    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
    };

    return (
        <div className='max-w-7xl mx-auto p-6 lg:px-8 py-20 '>
            <h2 className='text-center text-3xl font-bold'>
                Our working process

            </h2>
            <p className='text-center text-gray-500 text-lg pt-4 pb-12'>Our systematic and technology enabled approach, for the talent acquisition process encompasses of three key steps, that help us work on the needs of all key stakeholders

            </p>

            <div className='lg:grid lg:grid-cols-3 gap-6'>
                <div>
                    <Lottie options={{ animationData, loop: true }}
                        height={200}
                        width={200}
                    />
                    <h3 className='font-bold text-2xl text-center'>Client Analysis</h3>
                    <p className='text-center text-gray-500 pt-2'>We begin by gaining a thorough grasp of what customers desire, with the goal of listening carefully and documenting each insight.

                    </p>
                </div>
                <div>
                    <Lottie options={defaultOptions}
                        height={200}
                        width={200}
                    />
                    <h3 className='font-bold text-2xl text-center'>Candidate Assessment
                    </h3>
                    <p className='text-center text-gray-500 pt-2'>Our fundamental competence is in conducting thorough industry mapping to identify relevant trends and circumstances. Following that, we assist our clients in gaining access to the greatest talent pool possible.

                    </p>
                </div>
                <div>
                    <Lottie options={defaultOptions1}
                        height={200}
                        width={200}
                    />
                    <h3 className='font-bold text-2xl text-center'>Interview & Selection

                    </h3>
                    <p className='text-center text-gray-500 pt-2'>At TronixTechIT, we make certain that applicants satisfy the needed criteria as well as the expectations of our clients. Our domain consultants have years of experience and expertise in identifying and attracting the best individuals, ensuring that our clients receive outstanding service.

                    </p>
                </div>
            </div>
        </div>
    )
}
